




































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { State, Action, Getter } from "vuex-class";
import { api as appApi } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  components: {},
})
export default class AUserManagement extends Vue {
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: (currentBreadCrumb: any[]) => Promise<boolean>;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  get currentBreadCrumb(): any[] {
    return [
      {
        text: this.$t("views.audit.home"),
        to: { name: ROUTE_NAMES.DASHBOARDSCOPED },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.breadcrumb"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.role-title"),

        to: { name: ROUTE_NAMES.USER_MANAGEMENT_ROLES },
        activeclass: "",
      },
    ];
  }

  mounted() {
    this.setBreadCrumb(this.currentBreadCrumb);
    this.setMenuPanel();
  }
}
